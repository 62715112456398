import React, { useEffect, useState } from "react";
import geditorConfig from "./api_utils/geditor_config";

const Editor = () => {

  useEffect(() => {
    const editor = geditorConfig();
  }, []);
  return (
    <div id="editor"></div>
  );
};

export default Editor;
