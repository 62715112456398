import grapesjs from "grapesjs";
import gjsBlockBasic from "grapesjs-blocks-basic";
import tailwindComponent from "../plugins/tailwind";

const geditorConfig = () => {

  const editor = grapesjs.init({
    container: "#editor",
    showOffsets: true,
    fromElement: true,
    noticeOnUnload: false,
    storageManager: false,
   
    plugins: [
      tailwindComponent,
      gjsBlockBasic,
      'grapesjs-ga',
    ],
    pluginsOpts: {
      tailwindComponent: {},
      gjsBlockBasic: {},
      'grapesjs-ga': {},
    },
  });

  editor.on("run:preview", () => {
    console.log("It will trigger when we click on preview icon");
    editor.stopCommand("sw-visibility");
  });
  editor.on("stop:preview", () => {
    console.log("It will trigger when we click on cancel preview icon");
    editor.runCommand("sw-visibility");
  });
  editor.on("component:selected", (component) => {
    const newTool = {
      icon: "fa fa-plus-square",
      title: "Check Toolbar",
      commandName: "new-tool-cmd",
      id: "new-tool",
    };

    const defaultToolbar = component.get("toolbar");
    const checkAlreadyExist = defaultToolbar.find(
      (toolbar) => toolbar.command === newTool.commandName
    );
    if (!checkAlreadyExist) {
      defaultToolbar.unshift({
        id: newTool.id,
        attributes: { class: newTool.icon, title: newTool.title },
        command: newTool.commandName,
      });
      component.set("toolbar", defaultToolbar);
    }
  });

  setTimeout(() => {
    let categories = editor.BlockManager.getCategories();
    categories.each((category) => category.set("open", false));
  }, 1000);
  return editor;
};

export default geditorConfig;
